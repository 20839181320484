import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import ContactPerson from "../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../components/hero";
import { CaseList } from "../../components/caseList";

import doodle from "../../assets/images/services/strategy.svg";

const StrategieKonzeption = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-strategy.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="de" langPath="service.strategy.link">
      <Seo
        title="Digitale Strategie und Konzeption – Monday Consulting"
        description="Der erste Schritt zum digitalen Markenerlebnis für Ihre Kunden beginnt mit dem richtigen Konzept – verlassen Sie sich auf unsere Experten."
        keywords="Consulting, Digitale Strategie, Konzeption, Beratung, Requirements Engineering"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Beratung, die sich auszahlt"
        subline="Der erste Schritt zum digitalen Markenerlebnis für Ihre Kunden beginnt mit dem richtigen Konzept – verlassen Sie sich auf unsere Experten."
        section="Digitale Strategie & Konzeption"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Digitale Strategie & Konzeption"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img className="w-75" alt="Unser Weg zu Ihrem Ziel" src={doodle} />
          </div>
          <div className="col-md-6">
            <h2>Unser Weg zu Ihrem Ziel</h2>
            <p>
              Welcher Herausforderung Sie sich auch stellen – einem
              Webseite-Relaunch, dem Umzug in die Cloud oder einer neuen Marke -
              alles beginnt mit der richtigen digitalen Strategie und einem
              ausgeklügelten IT-Konzept. Mit Monday Consulting können Sie sich
              darauf verlassen, dass wir gemeinsam mit Ihnen jede
              Herausforderung meistern.
            </p>
            <p>
              Wir sehen uns als Ihren beratenden Partner. Das heißt,
              Ausgangspunkt für unsere Arbeit sind Ihre Anforderungen und Ziele.
              Der Markt hält viele digitale Lösungen für diverse Anwendungen
              bereit. Unsere Experten unterstützen Sie dabei, die Kriterien
              herauszukristallisieren, die für Ihren Erfolg maßgeblich sind. Nur
              wer den Bedarf kennt, kann sich für das richtige
              Content-Management-System und die passende Strategie entscheiden.
            </p>
            <p>
              Unsere Experten fertigen seit 2009 individuelle Konzepte für große
              und mittelständische Unternehmen an. Keine Lösung gleicht dabei
              der anderen, denn am Ende kommt es auf Ihr Unternehmen, Ihre
              Anforderungen und Ihre Wünsche an. Daher entwickeln wir gemeinsam
              die optimale Lösung für Sie.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Digitale Strategie & Konzeption im Detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-requirementsengineering bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Requirements Engineering
            </h3>
            <p>
              Eine umfassende Analyse bildet den Grundstein unserer
              strategischen Planung. Wir erfassen die Anforderungen aller
              Stakeholder und entwickeln gemeinsam mit Ihnen die optimale
              Lösung.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-digitalstrategy bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Digitale Strategie
            </h3>
            <p>
              Unser Ziel ist Ihr Erfolg. Daher erarbeiten wir für Sie die
              digitale Strategie, die Ihr Unternehmen wirklich nach vorne
              bringt.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-concept bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Konzeption
            </h3>
            <p>
              Verlassen Sie sich in Sachen Lösungsdesign und System-Architektur
              auf unsere Experten. Wir schöpfen aus jahrelanger Erfahrung und
              entwickeln das optimale IT-Konzept für Sie.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-cms bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Content-Management-Systeme
            </h3>
            <p>
              Sie wissen noch nicht, für welches CMS Sie sich entscheiden
              sollen? Wir finden für Sie die passende Antwort und zeigen Ihnen
              das System, welches Ihren Ansprüchen gerecht wird.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Ausgewählte Referenzen</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="bgw,generali" />
      </div>

      <ContactPerson
        headline="Dürfen wir Ihnen behilflich sein?"
        text="Überzeugen Sie sich noch heute von unseren Leistungen und nehmen Sie direkt Kontakt mit uns auf. Wir stehen Ihnen mit Rat und Tat zur Seite."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Geschäftsführer"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default StrategieKonzeption;
